.o-locations{
	--_gap-block: calc(12rem / 16);

	max-inline-size: calc( var(--max-width-content) + var(--padding-inline-body) * 2 );
	inline-size: 100%;
	margin-inline: auto;
	padding-inline: var(--padding-inline-body);
	padding-block-start: var(--padding-block-start-main);
}

.o-locations section{
	--_columns: 1;

	display: grid;
	grid-auto-flow: row dense;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	margin-inline: auto;
	grid-template-columns: minmax(11px, 1fr);
	column-gap: var(--gap-inline);
	margin-block-start: var(--gap-block-large);

	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: 1.75rem;

	@media (min-width: calc(976em / 16)) {
		--_columns: 12;

		border-block-start: none;
		padding-block-start: 0;
		grid-template-columns: repeat(var(--_columns), 1fr);
	}
}

.o-locations section > div:first-child{
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 10;
	}
}

.o-locations section:nth-child(odd) > div:first-child{
	@media (min-width: calc(976em / 16)) {
		grid-column: 4 / 14;
	}
}

.o-locations section > div:last-child{
	margin-block-start: var(--gap-block-medium);

	@media (min-width: calc(976em / 16)) {
		margin-block-start: 0;
		grid-column: 10 / -1;
		border-block-start: var(--border-width-medium) solid currentcolor;
		padding-block-start: 1.75rem;
	}
}

.o-locations section:nth-child(odd) > div:last-child{
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 4;
	}
}

.o-locations > section figure{
	width: 100%;
	img{ width: 100%; }
}

.o-locations h2 + *{
	margin-block-start: calc(var(--_gap-block) * 2);
}

.o-locations > section > div > div:last-child{
	margin-block-start: calc( var(--padding-inline-body) );
}

.o-locations > section a{
	text-decoration: none;
}

.o-locations > section a:not(.a-button):where(:hover, :focus-visible) {
	text-decoration: underline;
}
