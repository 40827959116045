.m-filter-persons {
	display: grid;
	column-gap: calc(32rem / 16);
	row-gap: calc(16rem / 16);
	justify-content: start;
	justify-items: start;
	color: var(--color-content-primary-inverse);
	background-color: var(--color-red);

	> label {
		inline-size: 100%;
	}

	@media (min-width: 990px) {
		grid-template-columns:
			minmax(calc(380rem / 16), calc(540rem / 16))
			minmax(calc(320rem / 16), calc(380rem / 16))
			auto;
	}
}
