.m-publications {
	--_columns: 3;
	--_gap: calc(12rem / 16);

	> h2 + nav {
		margin-block-start: calc(32rem / 16);
	}

	@media (min-width: calc(928em / 16)) {
		--_gap: var(--gap-inline);
	}
}


.m-publications__list {
	position: relative;
	display: grid;
	gap: var(--_gap);
	list-style: none;

	> li {
		> * {
			block-size: 100%;
		}
	}

	.m-publications:not([data-paginate]) & {
		grid-template-columns: repeat(
			auto-fill,
			minmax(
				min(calc(320rem / 16), calc(100vw - var(--padding-inline-body) * 2)),
				1fr
			)
		);
	}
	.m-publications[data-paginate] & {
		margin-inline: calc(var(--padding-inline-body) * -1);
		display: flex;
		padding-block-end: 1px;
		padding-inline: var(--padding-inline-body);
		overflow: auto;
		scroll-snap-type: x mandatory;
		scroll-padding-inline-start: var(--padding-inline-body);
		scrollbar-width: none;

		> li {
			scroll-snap-align: start;
			flex-basis: calc(272rem / 16);
			flex-shrink: 0;
			flex-grow: 0;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	nav + &,
	h2 + & {
		margin-block-start: calc(32rem / 16);
	}

	& + .a-button {
		margin-block-start: calc(32rem / 16);
	}

	@media (min-width: calc(928em / 16)) {
		.m-publications[data-paginate] & {
			margin-inline: 0;
			padding-inline: 0;
			scroll-padding-inline-start: 0;

			> li {
				flex-basis: calc(100% / var(--_columns) - var(--_gap) * (var(--_columns) - 1) / var(--_columns));
				scroll-snap-align: none;

				&:nth-child(3n - 2) {
					scroll-snap-align: start;
				}
			}
		}
	}
}

/* Slider Fix */

@media (max-width: 927px) {
	.m-publications__list {
		.m-publications[data-paginate] & {
			> li {
				flex-basis: 50%;
			}
		}
	}
}

@media (max-width: 640px) {
	.m-publications__list {
		.m-publications[data-paginate] & {
			> li {
				flex-basis: 100%;
			}
		}
	}
}
