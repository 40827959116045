/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * © 2024 MyFonts Inc. */


/* @font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 100;
	src: url('/assets/fonts/ff-meta/FFMetaHairline/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaHairline/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 100;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaHairlineItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaHairlineItalic/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Thin";
	font-weight: 200;
	src: url('/assets/fonts/ff-meta/FFMetaThin/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaThin/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 200;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaThinItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaThinItalic/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 300;
	src: url('/assets/fonts/ff-meta/FFMetaLight/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaLight/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 300;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaLightItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaLightItalic/font.woff') format('woff');
} */
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	src: url('/assets/fonts/ff-meta/FFMetaRegular/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaRegular/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaItalic/font.woff') format('woff');
}
/* @font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 450;
	src: url('/assets/fonts/ff-meta/FFMetaBook/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaBook/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-style: italic;
	font-weight: 450;
	src: url('/assets/fonts/ff-meta/FFMetaBookItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaBookItalic/font.woff') format('woff');
} */
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 500;
	src: url('/assets/fonts/ff-meta/FFMetaMedium/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaMedium/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 500;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaMediumItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaMediumItalic/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 700;
	src: url('/assets/fonts/ff-meta/FFMetaBold/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaBold/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 700;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaBoldItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaBoldItalic/font.woff') format('woff');
}
/* @font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 900;
	src: url('/assets/fonts/ff-meta/FFMetaBlack/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaBlack/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta";
	font-display: swap;
	font-weight: 900;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaBlackItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaBlackItalic/font.woff') format('woff');
} */








/* @font-face {
	font-family: "FF Meta Condensed";
	src: url('/assets/fonts/ff-meta/FFMetaCondensed/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensed/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedItalic/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 450;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedBook/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedBook/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 450;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedBookItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedBookItalic/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 500;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedMedium/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedMedium/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 500;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedMediumItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedMediumItalic/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 700;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedBold/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedBold/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 700;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedBoldItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedBoldItalic/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 800;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedExtrabold/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedExtrabold/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 800;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedExtraboldItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedExtraboldItalic/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 900;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedBlack/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedBlack/font.woff') format('woff');
}
@font-face {
	font-family: "FF Meta Condensed";
	font-weight: 900;
	font-style: italic;
	src: url('/assets/fonts/ff-meta/FFMetaCondensedBlackItalic/font.woff2') format('woff2'), url('/assets/fonts/ff-meta/FFMetaCondensedBlackItalic/font.woff') format('woff');
} */
