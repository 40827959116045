.m-person-contact {
	display: flex;
	block-size: 100%;
	flex-direction: column;
	padding-inline: calc(32rem / 16);
	padding-block-start: calc(48rem / 16);
	padding-block-end: calc(36rem / 16);

	> p {
		font: var(--font-label-medium);
	}

	> p + p {
		margin-block-start: calc(8rem / 16);
	}

	h2 + * {
		margin-block-start: calc(16rem / 16);
	}

	b {
		font-weight: 500;
	}

	a {
		font-weight: 500;
		text-decoration: none;
	}

	> p > a {
		&:focus-visible,
		&:hover {
			text-decoration: underline;
		}
	}
}


.m-person-contact__links {
	margin-block-start: calc(20rem / 16);
	display: flex;
	list-style: none;
	gap: calc(10rem / 16);
}


.m-person-contact__location {
	margin-block-start: auto;
	padding-block-start: calc(20rem / 16);
	font: var(--font-label-medium);

	> svg {
		margin-block-end: calc(36rem / 16);
		max-inline-size: calc(180rem / 16);

		* {
			fill: currentcolor !important; /* overwrite style property */
		}
	}
}
