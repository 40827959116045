.m-person-groups {
	--_gap-block: calc(12rem / 16);

	> h2 + ul {
		margin-block-start: calc(32rem / 16);
	}

	ul {
		list-style: none;
	}

	li + li {
		margin-block-start: var(--_gap-block);
	}

	> ul > li + li > ul {
		padding-block-start: var(--_gap-block);
		border-block-start: var(--border-width-small) solid currentcolor;
	}
}
