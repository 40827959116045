.m-articles {
	--_rows: 5;

	> ul {
		display: grid;
		grid-auto-rows: auto;
		row-gap: calc(16rem / 16);
		list-style: none;
	}

	> h2 + ul {
		margin-block-start: calc(32rem / 16);
	}

	> ul + .a-button {
		margin-block-start: calc(32rem / 16);
	}

	.o-main > & {
		margin-block-start: calc(32rem / 16 * -1);
	}


	&[data-variant="news"],
	&[data-variant="subgrid"] {
		h2 + ul {
			margin-block-start: 0;
		}
		> ul {
			> li {
				display: grid;
				margin-block-start: calc(32rem / 16);
				grid-row-end: span var(--_rows);
				grid-template-rows: subgrid;
			}
		}
	}
	&[data-variant="home"] {
		> ul {
			> li {
				margin-block-end: calc(40rem / 16);
				display: grid;
				grid-row-end: span var(--_rows);
				grid-template-rows: subgrid;
			}
		}
	}

	@media (min-width: calc(640em / 16)) {
		> ul {
			grid-template-columns: repeat(
				auto-fill,
				minmax(
					min(calc(320rem / 16), calc(100vw - var(--padding-inline-body) * 2)),
					1fr
				)
			);
			column-gap: var(--gap-inline);
			list-style: none;

			> li {
				display: grid;
				align-items: start;
			}
		}

		&[data-variant="news"] {
			> ul {
				grid-template-columns: repeat(
					auto-fill,
					minmax(
						min(calc(352rem / 16), calc(100vw - var(--padding-inline-body) * 2)),
						1fr
					)
				);
			}
		}

		&[data-variant="home"] {
			> ul {
				grid-template-columns: repeat(
					auto-fill,
					minmax(
						min(calc(352rem / 16), calc(100vw - var(--padding-inline-body) * 2)),
						1fr
					)
				);
			}
		}
	}

}


.m-articles__pagination {
	margin-block-start: calc(48rem / 16);
}
