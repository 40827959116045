.m-footer-location {
	> strong {
		text-transform: uppercase;
		letter-spacing: var(--letter-spacing-uppercase);
		font: var(--font-label-small);
		font-weight: 500;
	}

	> p {
		margin-block-end: calc(16rem / 16);
		font: var(--font-paragraph-xsmall);

		> a {
			text-decoration: none;

			&:focus-visible,
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
