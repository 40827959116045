.o-article {
	--_columns: 12;

	margin-inline: auto;
	display: grid;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);
	grid-template-columns: minmax(1px, 1fr);
	column-gap: var(--gap-inline);
	row-gap: var(--gap-block-medium);

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);
		row-gap: var(--gap-block-large);
	}
}


.o-article__header {
	> h1 + p {
		margin-block-start: calc(8rem / 16);
	}
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 10;
	}
}


.o-article__text {
	> figure {
		margin-block-end: var(--gap-block-small);
	}

	> figure[data-theme="border-red"] {
		> img {
			border: var(--border-width-small) solid var(--color-red);
		}
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 10;

		> figure {
			float: right;
			margin-inline-start: var(--gap-inline);
			margin-block-end: var(--gap-block-medium);
			inline-size: calc(592rem / 16);
		}
	}
}


.o-article__aside {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(32rem / 16);

	@media (min-width: calc(976em / 16)) {
		position: sticky;
		inset-block-start: calc(148rem / 16);
		align-self: start;
		grid-column: 10 / -1;
	}
}


.o-article__competencies {
	.m-person-groups + & {
		margin-block-start: var(--gap-block-large);
		border-block-start: var(--border-width-medium) solid currentcolor;
		padding-block-start: calc(32rem / 16);
	}
}
