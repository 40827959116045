.m-navigation {
	&[data-type="secondary"] {
		overflow: auto;
		display: flex;
	}
	@media not (min-width: calc(1152em / 16)), (max-width: 72em) {
		&[data-type="secondary"] {
			margin-inline: calc(var(--padding-inline-body) * -1);
			padding-inline: calc(var(--padding-inline-body) - 16rem / 16);
		}
	}
}

.m-navigation__container {
	display: flex;
	@media not (min-width: calc(1152em / 16)), (max-width: 72em) {
		flex-direction: row-reverse;
	}
	@media (min-width: calc(1152em / 16)), (min-width: 72em) {
		column-gap: calc(60rem / 16);
	}
}

.m-navigation__list {
	@media (min-width: calc(1152em / 16)), (min-width: 72em) {
		/* desktop nav */
		display: flex;
		align-content: end;
		column-gap: calc(20rem / 16);

		> li[data-variant="lang-switch"] {
			display: none;
		}
	}
	@media not (min-width: calc(1152em / 16)), (max-width: 72em) {
		.m-navigation[data-type="primary"] & {
			/* Offscreen nav (hamburger nav) */
			display: none;

			.m-navigation[data-open] & {
				--_block-size-navigation: calc(44rem / 16 + 20rem / 16 * 2 + var(--border-width-medium));

				position: absolute;
				z-index: 1;
				inset-inline: 0;
				inset-block-start: var(--_block-size-navigation);
				inset-block-end: 0;
				display: flex;
				flex-direction: column;
				padding-block-start: calc(20rem / 16);
				overflow: auto;
			}
		}

		.m-navigation[data-type="secondary"] & {
			display: flex;
		}

		> li[data-variant="lang-switch"] {
			margin-block-start: auto;
			padding-block-start: calc(48rem / 16);
			font: var(--font-label-small);
			text-align: end;
			& + li[data-variant="lang-switch"]{
				margin-block-start: 0;
				padding-block-start: 0;
			}
		}
	}
}

.m-navigation__meta {
	display: flex;
}

.m-navigation__switch-language {
	@media not (min-width: calc(1152em / 16)), (max-width: 72em) {
		display: none;
	}
	@media (min-width: calc(1152em / 16)), (min-width: 72em) {
		display: flex;
		.a-nav-link + .a-nav-link{ padding-left: 0 !important; }
	}
}
