.o-about {
	margin-inline: auto;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);
}


.o-about__header {
	margin-block-end: calc(48rem / 16);

	> p {
		font: var(--font-label-medium);
	}

	> h1 + p {
		margin-block-start: calc(8rem / 16);
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
}


.o-about__grid {
	--_columns: 12;

	margin-block-start: var(--gap-block-large);
	display: grid;
	grid-template-columns: minmax(1px, 1fr);
	column-gap: var(--gap-inline);
	row-gap: var(--gap-block-xlarge);

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);
	}
}


.o-about__text,
.o-about__image {
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}

.o-about__text {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);
}

.o-about__image {
	> img {
		inline-size: 100%;
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
		grid-row-end: span 2;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}

.o-about__person-groups,
.o-about__logos,
.o-about__downloads {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);
	@media (min-width: calc(976em / 16)) {
		grid-column: 9 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 10 / -1;
	}
}

.o-about__logos {
	@media (min-width: calc(976em / 16)) {
		grid-row-end: span 2;
	}
}
