.m-services {
	--_gap-block: calc(12rem / 16);

	> h2 + ul {
		margin-block-start: calc(32rem / 16);
	}

	> ul {
		list-style: none;

		> li {
			font: var(--font-label-small);
			break-inside: avoid;

			> a {
				display: flex;
				text-decoration: none;
				margin-block-end: calc(var(--border-width-small) * -1);
				border-block: var(--border-width-small) solid currentcolor;
				padding-block: calc(20rem / 16);
				justify-content: space-between;
				align-items: center;
				text-wrap: balance;

				&::after {
					content: '';
					display: block;
					block-size: var(--line-height-label-small);
					inline-size: var(--line-height-label-small);
					flex-shrink: 0;
					mask-image: url('/assets/images/symbols/chevron-e.svg');
					mask-size: 100%;
					mask-repeat: no-repeat;
					background-color: currentcolor;
					transition-duration: var(--duration-small);
					transition-property: translate;
				}

				&:focus-visible,
				&:hover {
					&::after {
						translate: calc(2rem / 16) 0;
						transition-timing-function: ease-out;
					}
				}
			}
		}
	}
	> .m-services__text + ul {
		margin-block-start: calc(var(--_gap-block) * 4);
	}

	@media (min-width: calc(512em / 16)) {
		> ul {
			columns: 2;
			column-gap: var(--gap-inline);
		}
	}
}

.m-services__text {
	.m-services > h2 + & {
		margin-block-start: calc(var(--_gap-block) * 2);
	}
}
