/**
 * @todo Morph with .a-thumb-topic?
 * Small thumb used for in person template.
 */

.a-thumb-competency {
	display: flex;
	column-gap: calc(16rem / 16);
	padding-inline-end: calc(8rem / 16);
	text-wrap: balance;
	align-items: center;
	text-decoration: none;
	transition-duration: var(--duration-small);
	transition-property: background-color;

	> svg {
		flex-shrink: 0;
		inline-size: calc(72rem / 16);
		aspect-ratio: 1;
		background-color: var(--color-red);

		* {
			fill: var(--color-white) !important; /* overwrite style property */
		}
	}

	> strong {
		font: var(--font-label-small);
	}

	&:focus-visible,
	&:hover {
		background-color: var(--color-background-secondary);
	}
}
