.m-references {
	--_padding-block: calc(14rem / 16);
	--_image-width: calc(128rem / 16);

	&[data-size="wide"] {
		--_image-width: calc(160rem / 16);
	}

	> h2 + ul {
		margin-block-start: calc((32rem - 14rem) / 16);
	}

	> ul {
		display: grid;
		grid-template-columns: repeat(auto-fill, var(--_image-width));
		column-gap: var(--padding-inline-body);
		list-style: none;
	}

	> ul > li {
		max-inline-size: var(--_image-width);
	}

	> ul > li > a {
		display: block;
		padding-block: var(--_padding-block);
	}
	> ul > li:not(:has(> a)) {
		padding-block: var(--_padding-block);
	}

	> ul > li > img,
	> ul > li > a > img {
		max-inline-size: 100%;
		max-block-size: calc(100rem / 16);
		object-fit: contain;
		object-position: left top;
	}

	> ul > li.portrait-image > img,
	> ul > li.portrait-image > a > img {
		max-block-size: 85%;
	}

	@media (min-width: calc(976em / 16)) {
		> ul {
			grid-template-columns: 1fr;
		}
	}
}
