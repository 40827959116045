.o-search{
}

.o-search__header {
	color: var(--color-content-primary-inverse);
	background-color: var(--color-red);

	> div {
		margin-inline: auto;
		max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
		padding-block: var(--padding-block-start-main);
		padding-inline: var(--padding-inline-body);
		display: flex;
		flex-direction: column;
		gap: calc(32rem / 16);
	}
}

.o-search__form {
	display: flex;
	column-gap: calc(32rem / 16);
	row-gap: calc(16rem / 16);
	flex-wrap: wrap;

	> label {
		flex-shrink: 1;
		flex-grow: 1;
		max-inline-size: calc(540rem / 16);
		flex-basis: calc(476rem / 16);
	}
}

.o-search__results {
	inline-size: 100%;
	margin-inline: auto;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body)*2);
	padding-inline: var(--padding-inline-body);

  > ul {
		margin-block-start: var(--padding-block-start-main);
		border-block-start: var(--border-width-medium) solid var(--color-black);
		max-inline-size: calc(1104rem / 16);
    list-style: none;
		> li {
			border-block-end: var(--border-width-small) solid var(--color-black);
		}
  }
}

.o-search__pagination {
  margin-block-start: var(--gap-block-large);
}
