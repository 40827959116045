.a-input {
	padding-block: calc(12rem / 16) calc(8rem / 16);
	padding-inline: calc(20rem / 16);
	border: var(--border-width-small) solid currentcolor;
	border-radius: 0;
	background-color: color-mix(in hsl, var(--color-background-primary) 95%, currentcolor);
	font: var(--font-label-medium);
	font-weight: 500;

	select& {
		appearance: none;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-inline-end: calc(36rem / 16);
		background-size: 1.2em;
		background-size: 1lh;
		background-image: url(/assets/images/symbols/chevron-dropdown-inverse.svg);
		background-repeat: no-repeat;
		background-position: right calc(8rem / 16) center;
	}

	&:focus-visible {
		outline: 1px solid currentcolor;
		outline-offset: 0;
	}

	&[data-theme="red"] {
		color: var(--color-content-primary-inverse);
		background-color: color-mix(in hsl, var(--color-red) 95%, var(--color-black));
	}
}
