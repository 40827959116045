.a-search-result {
	display: block;
	box-sizing: border-box;
	padding-inline: calc(12rem / 16);
	padding-block: calc(12rem / 16);
	text-decoration: none;
	transition-duration: var(--duration-small);
	transition-property: background-color;

	&:hover{
			background-color: var(--color-background-secondary);
	}
}

.a-search-result__title {
	> em {
		font-style: normal;
	}
}

.a-search-result__breadcrumbs{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	> li {
		display: inline;
		color: var(--color-content-tertiary);
		font: var(--font-label-small);

		&:not(:last-of-type)::after {
			content: "\00a0/\00a0";
		}
	}
}
