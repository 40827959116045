.m-card-person {
	--_padding-inline: calc(12rem / 16);

	position: relative;
	display: flex;
	flex-direction: column;
	block-size: 100%;
	text-decoration: none;
	border: var(--border-width-small) solid currentcolor;
	transition-duration: var(--duration-small);
	transition-property: background-color;

	> img {
		inline-size: 100%;
		background-color: var(--color-background-primary);
	}

	&:has(a:where(:focus-visible, :hover)) {
		background-color: var(--color-background-secondary);
	}

	@media (min-width: calc(640em / 16)) {
		--_padding-inline: calc(20rem / 16);
	}
}


.m-card-person__text {
	text-wrap: balance;
	padding-block: calc(28rem / 16);
	padding-inline: var(--_padding-inline);

	> strong {
		display: block;
		font: var(--font-label-medium);
		font-weight: 500;
	}
	> em {
		margin-block-start: calc(16rem / 16);
		display: block;
		font: var(--font-label-small);
		color: var(--color-content-tertiary);
	}
}


.m-card-person__contact {
	margin-block-start: auto;
	padding-block-end: calc(28rem / 16);
	padding-inline: var(--_padding-inline);
	pointer-events: none;

	> a {
		pointer-events: auto;
		text-decoration: none;

		&:where(:focus-visible, :hover) {
			text-decoration: underline;
		}
	}
}


.m-card-person__link {
	position: absolute;
	inset: 0;
	z-index: 1;
}


.m-card-person__contact {
	position: relative;
	z-index: 2;

	> a {
		display: inline-block;
		font: var(--font-label-medium);
	}
}


.m-card-person__meta {
	padding-inline-start: var(--_padding-inline);
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--color-content-secondary);

	> .a-icon {
		margin-inline-start: auto;
	}
}
