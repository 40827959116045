.a-thumb-person {
	display: flex;
	column-gap: calc(16rem / 16);
	padding-block: calc(4rem / 16);
	padding-inline-end: calc(4rem / 16);
	text-wrap: balance;
	align-items: start;
	text-decoration: none;
	transition-duration: var(--duration-small);
	transition-property: background-color;

	> img {
		margin-block: calc(-4rem / 16);
		flex-shrink: 0;
		inline-size: calc(72rem / 16);
	}

	> div {
		margin-block: auto;
		display: flex;
		flex-direction: column;
		row-gap: calc(2rem / 16);

		> strong {
			display: block;
			font: var(--font-label-small);
		}
		> em {
			display: block;
			font: var(--font-label-xsmall);
			color: var(--color-content-secondary);
		}
	}

	&[data-size="small"] {
		> img {
			inline-size: calc(48rem / 16);
		}
	}

	&[data-variant="lead"] {
		> div > strong {
			font: var(--font-label-medium);
			font-weight: 500;
		}
	}

	a&:focus-visible,
	a&:hover {
		background-color: var(--color-background-secondary);
	}
}
