.m-competency-spider {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(calc(212rem / 16), 1fr));
	gap: var(--padding-inline-body);

	> ul {
		display: contents;
		list-style: none;

		> li {
			grid-column-end: span 1;
			display: flex;

			> a {
				flex-grow: 1;
				align-self: stretch;
			}
		}
	}

	@media (min-width: calc(640em / 16)) {
		gap: var(--gap-inline);
	}
	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(3, 1fr);
		grid-auto-flow: dense;
	}
}


.m-competency-spider__title {
	grid-column: 1 / -1;

	@media (min-width: calc(976em / 16)) {
		grid-row: 2 / span 1;
		grid-column: 2 / span 2;
		align-self: center;
		justify-self: center;
		text-align: center;
	}
}
