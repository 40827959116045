.m-grid {
	--_columns: 12;

	display: grid;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	margin-inline: auto;
	padding-inline: var(--padding-inline-body);
	grid-template-columns: minmax(1px, 1fr);
	column-gap: var(--gap-inline);
	row-gap: var(--gap-block-medium);

	&[data-align="start"] {
		align-items: start;
	}
	&[data-align="center"] {
		align-items: center;
	}
	&[data-align="end"] {
		align-items: end;
	}
	&[data-align="stretch"] {
		align-items: stretch;
	}

	&[data-justify="start"] {
		justify-items: start;
	}
	&[data-justify="center"] {
		justify-items: center;
	}
	&[data-justify="end"] {
		justify-items: end;
	}
	&[data-justify="stretch"] {
		justify-items: stretch;
	}

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);
	}
}
