.o-header {
	--background-color: color-mix(in hsl, var(--color-background-primary) 90%, transparent);
	--backdrop-filter: blur(20px);
	--_padding-inline: var(--padding-inline-body);

	position: sticky;
	inset-block-start: 0;
	z-index: var(--z-index-nav-sticky);
	padding-inline: var(--_padding-inline);
	background-color: var(--background-color);
	backdrop-filter: var(--backdrop-filter);

	&[data-variant="home"] {
		--background-color: color-mix(in hsl, hsla(349, 64%, 36%, 1) 90%, transparent);
		--backdrop-filter: blur(20px);

		position: fixed;
		inset-inline: 0;
		color: var(--color-content-primary-inverse);
		transition-duration: var(--duration-large);
		transition-property: background-color;

		&:not([data-scrolled]) {
			background-color: transparent;
			backdrop-filter: none;
		}
	}

	&:not(:has(.o-header__navigation-secondary)) {
		margin-block-end: calc(1.25rem * 2 + var(--line-height-label-medium));
	}
	&:has(.o-header__navigation-back) {
		margin-block-end: calc(14rem / 16);
	}

	@media not (min-width: calc(1152em / 16)), (max-width: 72em) {
		&:has(.m-navigation[data-open]) {
			block-size: 100dvb;
			background-color: var(--background-color);
			backdrop-filter: var(--backdrop-filter);
		}
	}
}


.o-header__container {
	margin-inline: auto;
	max-inline-size: calc(var(--max-width-content));
	inline-size: 100%;
	display: grid;
	grid-template-columns: minmax(1px, auto) minmax(1px, auto);
	justify-content: space-between;
	align-items: end;
}


.o-header__logo {
	margin-inline-start: calc(20rem / 16 * -1);
	padding: calc(20rem / 16);

	> img {
		block-size: calc(44rem / 16);
		inline-size: calc(135rem / 16);
	}

	@media not (min-width: calc(448em / 16)) {
		margin-inline-start: calc(16rem / 16 * -1);
		padding-inline: calc(16rem / 16);
	}
}

.o-header__line {
	grid-column: 1 / -1;
	margin-block-end: calc(var(--border-width-medium) * -1);
	border-block-end: var(--border-width-medium) solid currentcolor;
}

.o-header__navigation,
.o-header__navigation-secondary {
	grid-column: 2 / -1;
}

.o-header__navigation {
	margin-inline-end: calc(20rem / 16 * -1);

	@media not (min-width: calc(448em / 16)) {
		margin-inline-end: calc(16rem / 16 * -1);
	}
}

.o-header__navigation-secondary {
	display: flex;
	justify-content: space-between;
	align-items: end;
	min-block-size: calc(20rem / 16 * 2 + var(--line-height-label-medium));

	@media not (min-width: calc(1152em / 16)), (max-width: 72em) {
		/* Offscreen nav */
		grid-column: 1 / -1;
		max-inline-size: 100%;

		.o-header:has(.m-navigation[data-open]) & {
			opacity: 0;
		}
	}
}

.o-header__navigation-back {
	align-self: start;
	margin-block-start: calc(16rem / 16);
	margin-inline-start: calc(-12rem / 16);
}
