.m-topics {
	--_gap-block: calc(12rem / 16);

	> h2 + ul {
		margin-block-start: calc(32rem / 16);
	}

	> ul {
		list-style: none;
		display: grid;
		row-gap: var(--_gap-block);
	}

	&[data-variant="standalone"] {
		> ul {
			display: grid;
			column-gap: var(--gap-inline);
			row-gap: calc(24rem / 16);
			grid-template-columns: repeat(auto-fill, minmax(calc(336rem / 16), 1fr));
		}
	}
}
