/* Naming of colors and fonts inspired by Uber Base (https://base.uber.com/6d2425e9f/p/8898fb-color-legacy/b/065744) */

:root {
	/* Color Primitives */
	--color-white:    hsla(0, 0%, 100%, 1);
	--color-gray-50:  hsla(243, 9%, 95%, 1);
	--color-gray-100: hsla(240, 8.5%, 90%, 1);
	--color-gray-200: hsla(237, 8%, 80%, 1);
	--color-gray-300: hsla(234, 7.5%, 70%, 1);
	--color-gray-400: hsla(231, 7%, 62%, 1); /* voelker lightgray, PANTONE Cool Grey 7 */
	--color-gray-500: hsla(228, 6.5%, 50%, 1);
	--color-gray-600: hsla(225, 6%, 41%, 1); /* voelker gray, PANTONE Cool Grey 10 */
	--color-gray-700: hsla(222, 5.5%, 30%, 1);
	--color-gray-800: hsla(219, 5%, 20%, 1);
	--color-gray-900: hsla(216, 4.5%, 10%, 1);
	--color-gray-950: hsla(213, 4%,  5%, 1);
	--color-black:    hsla(0,   0%,  0%, 1);

	--color-red-50: hsla(349, 64%, 95%, 1);
	--color-red-100: hsla(349, 64%, 90%, 1);
	--color-red-200: hsla(349, 64%, 80%, 1);
	--color-red-300: hsla(349, 64%, 70%, 1);
	--color-red-400: hsla(349, 64%, 60%, 1);
	--color-red-500: hsla(349, 64%, 50%, 1);
	--color-red-600: hsla(349, 64%, 38%, 1); /* voelker red, PANTONE 201 */
	--color-red-700: hsla(349, 64%, 30%, 1);
	--color-red-800: hsla(349, 64%, 20%, 1);
	--color-red-900: hsla(349, 64%, 10%, 1);

	/* Foundation Colors */
	--color-red: var(--color-red-600);
	--color-gray: var(--color-gray-600);
	--color-lightgray: var(--color-gray-400);

	/* Core Colors */
	--color-background-primary: var(--color-white);
	--color-background-secondary: var(--color-gray-50);
	--color-background-tertiary: var(--color-gray-100);
	--color-background-primary-inverse: var(--color-black);
	--color-background-secondary-inverse: var(--color-gray-800);

	--color-content-primary: var(--color-black);
	--color-content-secondary: var(--color-gray-600);
	--color-content-tertiary: var(--color-gray-500);
	--color-content-primary-inverse: var(--color-white);
	--color-content-secondary-inverse: var(--color-gray-100);
	--color-content-tertiary-inverse: var(--color-gray-200);

	--color-backdrop: color-mix(in hsl, var(--color-black) 90%, transparent);

	--color-border-opaque: var(--color-gray-200);
	--color-border-transparent: color-mix(in hsl, var(--color-black) 8%, transparent);
	--color-border-selected: var(--color-content-primary);
	--color-border-opaque-inverse: var(--color-gray-700);
	--color-border-transparent-inverse: color-mix(in hsl, var(--color-white) 8%, transparent);
	--color-border-selected-inverse: var(--color-white);

	/* Shadows */
	--box-shadow: 0 1px 10px -calc(10rem / 16) rgba(0,0,0,0.1),
	              0 1.5px 20px -calc(10rem / 16) rgba(0,0,0,0.1),
	              0 2px 30px -calc(10rem / 16) rgba(0,0,0,0.1);
}
