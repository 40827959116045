.o-person {
	padding-block-start: var(--padding-block-start-main);
}


.o-person__header {
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	margin-inline: auto;
	padding-inline: var(--padding-inline-body);

	> p {
		font: var(--font-label-medium);
	}

	> h1 + p {
		margin-block-start: calc(8rem / 16);
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
}


.o-person__grid {
	--_columns: 12;

	margin-block-start: var(--gap-block-large);
	margin-inline: auto;
	display: grid;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	padding-inline: var(--padding-inline-body);
	grid-template-columns: minmax(11px, 1fr);
	column-gap: var(--gap-inline);
	row-gap: var(--gap-block-xlarge);

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);
	}
}


.o-person__teaser-image {
	> img {
		inline-size: 100%;
		block-size: 100%;
		object-fit: cover;
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}


.o-person__contact {
	color: var(--color-content-primary-inverse);
	background-color: var(--color-red);

	@media (min-width: calc(976em / 16)) {
		grid-column: 9 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 10 / -1;
		align-self: stretch;
  }
}


.o-person__text,
.o-person__competencies,
.o-person__references,
.o-person__publications,
.o-person__quotes,
.o-person__talks,
.o-person__articles {
	padding-block-start: calc(28rem / 16);
	border-block-start: var(--border-width-medium) solid currentcolor;

	> h2 {
		margin-block-end: calc(32rem / 16);
	}
}

.o-person__text {
	> figure {
		margin-block-end: var(--gap-block-small);
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
	}
	@media (min-width: calc(1152em / 16)) {
		> figure {
			float: right;
			margin-inline-start: var(--gap-block-medium);
			margin-block-end: var(--gap-block-medium);
		}
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;

  }
}


.o-person__competencies {
	@media (min-width: calc(976em / 16)) {
		grid-column: 9 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 10 / -1;
	}
}


.o-person__references {
	@media (min-width: calc(976em / 16)) {
		grid-column: 9 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 10 / -1;
	}
}


.o-person__publications {
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}

.o-person__quotes {
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}

.o-person__talks {
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}

.o-person__articles {
	> ul {
		display: grid;
		grid-auto-rows: 1fr;
		grid-template-columns: repeat(
			auto-fill,
			minmax(
				min(calc(320rem / 16), calc(100vw - var(--padding-inline-body) * 2)),
				1fr
			)
		);
		column-gap: calc(40rem / 16);
		row-gap: calc(40rem / 16);
		list-style: none;

		> li {
			> * {
				block-size: 100%;
			}
		}
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}
