.m-card-publication {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: calc(20rem / 16);
	padding-block-start: calc(40rem / 16);
	padding-block-end: calc(48rem / 16);
	padding-inline: calc(32rem / 16);
	text-decoration: none;
	font: var(--font-paragraph-medium);
	border: var(--border-width-small) solid currentcolor;
	transition-duration: var(--duration-small);
	transition-property: background-color;

	> em {
		text-wrap: balance;
		font-style: normal;
		font: var(--font-label-medium);
	}

	> small {
		display: block;
		text-wrap: balance;
		margin-block-start: auto;
		font: var(--font-label-small);
	}

	> .a-icon {
		position: absolute;
		inset-block-end: 0;
		inset-inline-end: 0;
	}

	a&:where(:focus-visible, :hover) {
		background-color: var(--color-background-secondary);
	}
}


.m-card-publication__title {
	text-wrap: balance;
	font-weight: 500;
}
