.a-display {
	text-wrap: balance;
	font: var(--font-display-medium);
	letter-spacing: var(--letter-spacing-display);

	&:has(> small) {
		> small {
			display: block;
			margin-block-start: calc(8rem / 16);
			font: var(--font-heading-large);
			letter-spacing: 0;
			font-weight: 400;
		}
	}
}
