.a-thumb-person-extended {
	display: flex;
	column-gap: calc(16rem / 16);
	text-wrap: balance;
	align-items: start;
	text-decoration: none;
	transition-duration: var(--duration-small);
	transition-property: background-color;

	> img {
		flex-shrink: 0;
		margin-block-start: calc(1rem / 16);
		inline-size: min(calc(216rem / 16), 25vw);
	}

	> div {
		display: flex;
		flex-direction: column;
		row-gap: calc(12rem / 16);
		font: var(--font-label-medium);
	}

	@media (min-width: calc(560em / 16)) {
		column-gap: calc(32rem / 16);
	}
}


.a-thumb-person-extended__name {
	font: var(--font-label-medium);

	> em {
		font-style: normal;
	}
}


.a-thumb-person-extended__contact {
	a {
		text-decoration: none;

		&:focus-visible,
		&:hover {
			text-decoration: underline;
		}
	}
}
