.o-event {
	--_columns: 12;

	margin-inline: auto;
	display: grid;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);
	grid-template-columns: minmax(1px, 1fr);
	column-gap: var(--gap-inline);
	row-gap: var(--gap-block-large);

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);
	}
}


.o-event__header {
	> strong {
		display: block;
		text-transform: uppercase;
		font: var(--font-label-large);
		font-weight: 700;
		letter-spacing: var(--letter-spacing-uppercase);
	}
	> time {
		display: block;
		margin-block-end: calc(12rem / 16);
		font: var(--font-label-large);
		font-weight: 500;
	}
	> strong + time {
		text-decoration: line-through;
	}
	> h1 + p {
		margin-block-start: calc(8rem / 16);
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 10;
	}
}


.o-event__text {
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 6;
	}

	.o-event:not([data-variant="no-text"]) & {
		@media (min-width: calc(976em / 16)) {
			grid-column: 1 / 10;

			> figure {
				float: right;
				margin-inline-start: var(--gap-inline);
				margin-block-end: var(--gap-block-medium);
				inline-size: calc(592rem / 16);
			}
		}
	}
}


.o-event__aside {
	@media (min-width: calc(976em / 16)) {
		position: sticky;
		inset-block-start: calc(148rem / 16);
		align-self: start;
		grid-column: 10 / -1;
	}
}

.o-event__details {
	background-color: var(--color-red);
	color: var(--color-content-primary-inverse);
}

.o-event__persons {
	margin-block-start: var(--gap-block-large);
	padding-block-start: calc(32rem / 16);
	border-block-start: var(--border-width-medium) solid currentcolor;
}

.o-event__competencies {
	margin-block-start: var(--gap-block-large);
	padding-block-start: calc(28rem / 16);
	border-block-start: var(--border-width-medium) solid currentcolor;

	> h2 {
		margin-block-end: calc(32rem / 16);
	}
}
