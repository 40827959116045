/** @todo Morph with .a-thumb-competency? */

.a-thumb-topic {
	display: flex;
	column-gap: calc(16rem / 16);
	padding-block: calc(4rem / 16);
	padding-inline-end: calc(8rem / 16);
	text-wrap: balance;
	align-items: center;
	text-decoration: none;
	font: var(--font-label-small);
	overflow: hidden;
	text-overflow: ellipsis;
	transition-duration: var(--duration-small);
	transition-property: background-color;

	> figure {
		margin-block: calc(-4rem / 16);
		align-self: start;
		flex-shrink: 0;
		inline-size: calc(72rem / 16);
		aspect-ratio: 1;
		background-color: var(--color-red);
	}
	> figure > svg {
		inline-size: 100%;

		* {
			fill: var(--color-white) !important;
			stroke: var(--color-white) !important;
		}
	}

	&[data-size="small"] {
		> figure {
			inline-size: calc(48rem / 16);
		}
	}

	&:focus-visible,
	&:hover {
		background-color: var(--color-background-secondary);
	}
}
