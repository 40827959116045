.o-topic {
	--_columns: 12;

	margin-inline: auto;
	display: grid;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);
	grid-template-columns: minmax(1px, 1fr);
	column-gap: var(--gap-inline);
	row-gap: var(--gap-block-large);

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);

		> h1 {
			grid-column: 1 / 10;
		}
	}
}


.o-topic__text {
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 10;

		> figure {
			float: right;
			margin-inline-start: var(--gap-inline);
			margin-block-end: var(--gap-block-medium);
			inline-size: calc(592rem / 16);
		}
	}
}


.o-topic__aside {
	@media (min-width: calc(976em / 16)) {
		position: sticky;
		inset-block-start: calc(148rem / 16);
		align-self: start;
		grid-column: 10 / -1;
	}
}
