.o-main {
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	padding-inline: var(--padding-inline-body);
	padding-block-start: calc(48rem / 16);
	row-gap: var(--gap-block-large);
}


.o-main__text {
	max-inline-size: var(--max-width-text);
}
