.o-home__hero {

	padding-block-start: calc( 150px + ( var(--padding-block-start-main) * 2 ) );
	padding-block-end: calc( var(--padding-block-start-main) * 2 );

	position: relative;
	color: var(--color-content-primary-inverse);

	> h1 {
		padding-block-end: var(--padding-block-start-main);
	}

	> nav {
		padding-block-start: calc( var(--padding-block-start-main) * 2 );
	}
}

.o-home__hero-background-image {

	width: 100%;
	height: 100%;
	display: block;

	top: 0;
	left: 0;
	position: absolute;

	background-color: var(--color-red);

	> img {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;

		mix-blend-mode: multiply;
		filter: grayscale(60%) contrast(0.9);
	}

}

.o-home__hero > *:not(.o-home__hero-background-image) {

	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	margin-inline: auto;
	inline-size: 100%;
	padding-inline: var(--padding-inline-body);
	position: relative;

	@media (min-width: calc(640em / 16)) {
		> *{
			max-width: calc( ( 100% - ( 4 * 3rem ) ) / 5 * 4 + ( 3rem * 3 ) );
		}
	}

}

.o-home__hero > nav > ul{

	display: flex;
	flex-direction: row;
	gap: var(--padding-inline-body);
	flex-wrap: wrap;

	@media (min-width: calc(640em / 16)) {
		gap: var(--gap-inline);
	}
}

.o-home__intro {
	margin-block-start: var(--gap-block-large);
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	margin-inline: auto;
	padding-inline: var(--padding-inline-body);
	display: grid;
	gap: var(--padding-inline-body);

	@media (min-width: calc(640em / 16)) {
		grid-template-columns: repeat(12, 1fr);
		gap: var(--gap-inline);

		> h2 {
			grid-column-end: span 4;
		}
		> div {
			grid-column-end: span 8;
		}
	}
}


.o-home__competencies {
	margin-block-start: var(--gap-block-xlarge);
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	margin-inline: auto;
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);
	display: grid;

	> div {
		border-block-start: var(--border-width-medium) solid currentcolor;
		padding-block-start: calc(32rem / 16);
	}

	> div > ul {
		display: grid;
		padding-block-start: var(--gap-inline);
		grid-template-columns: repeat(2, minmax(1px, 1fr));
		gap: var(--padding-inline-body);
		list-style: none;
	}

	@media (min-width: calc(640em / 16)) {
		> div > ul {
			grid-template-columns: repeat(auto-fill, minmax(calc(180rem / 16), 1fr));
		}
	}
	@media (min-width: calc(800em / 16)) {
		> div > ul {
			grid-template-columns: repeat(auto-fill, minmax(calc(228rem / 16), 1fr));
		}
	}
	@media (min-width: calc(1600em / 16)) {
		> div > ul {
			gap: var(--gap-inline);
		}
	}
}

.o-home__locations {
	margin-block-start: var(--gap-block-xlarge);
	margin-inline: auto;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);

	> div {
		border-block-start: var(--border-width-medium) solid currentcolor;
		padding-block-start: calc(32rem / 16);
	}

	/* Scroll container */
	> div > div {
		display: flex;
		overflow: auto;
		margin-inline: calc(var(--padding-inline-body) * -1);
		padding-inline: var(--padding-inline-body);
	}
	> div > div > ul {
		flex-grow: 1;
		list-style: none;
		display: flex;
		flex-direction: row;
		gap: var(--padding-inline-body);
		padding-block-start: var(--padding-block-start-main);

		> li {
			flex-grow: 1;
			min-width: calc(254rem / 16);
			display: flex;
			flex-direction: column;
			gap: var(--gap-inline);
			align-items: center;
			padding-block: calc( var(--padding-block-start-main) * 2 );
			padding-inline: var(--padding-inline-body);
			box-sizing: border-box;
			background-color: var(--color-background-secondary);

			> a {
				text-wrap: nowrap;
			}
		}
	}

	@media (min-width: calc(976em / 16)) {
		> div > div > ul {
			gap: var(--gap-inline);
		}
	}
}


.o-home__events {
	margin-block-start: var(--gap-block-xlarge);
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	margin-inline: auto;
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);
	display: grid;

	> div {
		border-block-start: var(--border-width-medium) solid currentcolor;
		padding-block-start: calc(32rem / 16);
	}
}


.o-home__articles {
	margin-block-start: var(--gap-block-xlarge);
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	margin-inline: auto;
	padding-inline: var(--padding-inline-body);
	display: grid;

	> div {
		border-block-start: var(--border-width-medium) solid currentcolor;
		padding-block-start: calc(32rem / 16);
	}
}
