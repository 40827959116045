html {
	font-family: var(--font-family);
	scroll-padding-top: calc(160rem / 16);
	hyphens: auto;
	-webkit-hyphens: auto;
	-webkit-hyphenate-limit-after: var(--hyphenate-limit);
	-webkit-hyphenate-limit-before: var(--hyphenate-limit);
	hyphenate-limit-chars: calc(var(--hyphenate-limit) * 2) var(--hyphenate-limit) var(--hyphenate-limit);
	color: var(--color-black);
	background-color: var(--color-white);
}

body {
	font: var(--font-paragraph-medium);
	display: flex;
	flex-direction: column;
}

html[data-no-scroll] {
	overflow: hidden;
	block-size: 100%;
}

html:has(.m-navigation[data-open]) {
	@media not (min-width: calc(1152em / 16)), (max-width: 72em) {
		overflow: hidden;
		block-size: 100%;
	}
}

