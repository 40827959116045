.m-card-event {
	text-decoration: none;

	> img {
		inline-size: 100%;
	}

	&:not([data-highlight]) {
		display: grid;
		grid-template-columns: calc(152rem / 16) 1fr;
		column-gap: calc(20rem / 16);
	}
	&[data-highlight] {
		max-inline-size: calc(592rem / 16);
		display: flex;
		flex-direction: column;
		row-gap: calc(20rem / 16);
	}
}

.m-card-event__container-text {
	display: grid;
	font: var(--font-label-medium);
	align-content: start;

	> time {
		display: block;
		text-transform: uppercase;
		font: var(--font-label-small);
	}
	> strong,
	> em {
		display: block;
	}
	> em {
		font-style: normal;
		font: var(--font-label-small);
	}
	> strong + em {
		margin-block-start: calc(4rem /  16);
	}
	> strong {
		font-weight: 500;
	}

	.m-card-event:not([data-highlight]) & {
		> strong + em {
			margin-block-start: calc(5rem /  16);
		}
		> time {
			margin-block-end: calc(4rem / 16);
			font: var(--font-label-small);
		}
	}
	.m-card-event:where([data-highlight]) & {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: calc(20rem / 16);

		> * {
			grid-column-start: 2;
		}

		> time {
			grid-column-start: 1;
			grid-row-end: span 3;
			align-self: start;
			box-sizing: content-box;
			padding: calc(4rem / 16);
			text-align: center;
			inline-size: 5ch;
			color: var(--color-content-primary-inverse);
			background-color: var(--color-red);
		}

		> strong,
		> em {
			font: var(--font-heading-medium);
		}
		> em {
			font-weight: 400;
		}
	}
}


.m-card-event__competencies {
	margin-block-start: calc(6rem / 16);
	column-gap: calc(4rem / 16);
	color: var(--color-content-secondary);
	font: var(--font-label-small);

	> span {
		display: inline-block;

		&:not(:last-child) {
			padding-inline-end: calc(12rem / 16);
		}
	}
}
