.a-button-text {
	display: inline-flex;
	font: var(--font-label-medium);
	font-weight: 500;
	text-decoration: none;

	&::after {
		content: '';
		display: block;
		margin-inline-start: calc(4rem / 16);
		inline-size: calc(16rem / 16);
		mask-image: url('/assets/images/symbols/chevron-e.svg');
		mask-size: 1.6em;
		mask-position: center 90%;
		mask-repeat: no-repeat;
		background-color: currentcolor;
	}
}
