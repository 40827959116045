.a-heading {
	text-wrap: balance;
	font: var(--font-heading-large);

	&[role="button"] {
		cursor: pointer;
	}
}

h2.a-heading,
.a-heading[data-size="large"] {
	font: var(--font-heading-large);
	text-transform: uppercase;
	letter-spacing: var(--letter-spacing-uppercase);
}
h3.a-heading,
.a-heading[data-size="medium"] {
	font: var(--font-heading-medium);
}
h4.a-heading,
.a-heading[data-size="small"] {
	font: var(--font-heading-small);
}
h5.a-heading,
.a-heading[data-size="xsmall"] {
	font: var(--font-heading-xsmall);
}
