.o-pre-location{
	width: 100%;
	max-inline-size: calc( var(--max-width-content) + var(--padding-inline-body) * 2 );
	margin-inline: auto;
	padding-inline: var(--padding-inline-body);
	padding-block-start: var(--padding-block-start-main);

	> h1 + div {
		margin-block-start: calc(8rem / 16);
		font: var(--font-label-medium);
	}
}

.o-location{
	--_gap-block: calc(12rem / 16);
	--_columns: 12;

	display: grid;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	margin-inline: auto;
	padding-inline: var(--padding-inline-body);
	grid-template-columns: minmax(11px, 1fr);
	column-gap: var(--gap-inline);
	margin-top: var(--gap-block-large);

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);
	}
}

.o-location__hero {
	img {
		inline-size: 100%;
	}
}

.o-location > div{
	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 10;
	}
}

.o-location section{
	& + section{
		margin-block-start: var(--padding-block-start-main);
		border-block-start: var(--border-width-medium) solid currentcolor;
		padding-block-start: 1.75rem;
	}
}

.o-location h2 + *{
	margin-block-start: calc(var(--_gap-block) * 2);
}

.o-location ul{
	list-style: none;
	padding-inline-start: 0;
}


.o-location__contact{
	figure{
		margin-block-start: calc(var(--_gap-block)*2);
	}
	@media (min-width: calc(640em / 16)) {
		display: flex;
		flex-direction: row;
		gap: var(--gap-inline);
		justify-content: space-between;
		figure{
			margin-block-start: 0;
			max-width: calc(60%);
		}
	}
}

.o-location__contact a{
	text-decoration: none;
}
.o-location__contact a:hover{
	text-decoration: underline;
}

.o-location > aside{
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: 1.75rem;
	margin-block-start: calc(var(--line-height-paragraph-medium)*2);

	@media (min-width: calc(976em / 16)) {
		margin-block-start: 0;
		grid-column: 10 / -1;
	}
}
