.a-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	block-size: calc(36rem / 16);
	aspect-ratio: 1;

	> svg {
		block-size: calc(28rem / 16);
		aspect-ratio: 1;

		* {
			fill: currentcolor;
		}
	}

	&[data-size="small"] {
		block-size: calc(28rem / 16);
		padding: calc(1rem / 16);
	}

	&[data-theme="red"] {
		color: var(--color-content-primary-inverse);
		background-color: var(--color-red);
	}
}
