.m-quotes {
	> h2 + nav {
		margin-block-start: calc(32rem / 16);
	}

	> nav + ul {
		margin-block-start: calc(32rem / 16);
	}

	> ul {
		overflow: auto;
		display: flex;
		column-gap: var(--gap-inline);
		list-style: none;
		scroll-snap-type: x mandatory;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		> li {
			inline-size: 100%;
			flex-shrink: 0;
			scroll-snap-align: start;
		}
	}

	@media (prefers-reduced-motion: no-preference) {
		> ul {
			scroll-behavior: smooth;
		}
	}
}
