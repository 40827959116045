.o-journals {
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	padding-inline: var(--padding-inline-body);
	padding-block-start: calc(48rem / 16);
	row-gap: var(--gap-block-large);
}

.o-journals__header {
	display: flex;
	flex-direction: column;
	row-gap: var(--gap-block-large);
}

.o-journals__list-container {
	inline-size: 100%;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	margin-inline: auto;
}


.o-journals__list-container > ul {
	--_columns: 5;

	display: grid;
	grid-template-columns: repeat(2, minmax(1px, 1fr));
	gap: var(--padding-inline-body);
	align-items: stretch;
	list-style: none;

	@media (min-width: calc(640em / 16)) {
		grid-template-columns: repeat(auto-fill, minmax(calc(212rem / 16), 1fr));
		gap: var(--gap-inline);
		grid-auto-rows: 1fr;
	}
}
