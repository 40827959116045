/**
 * @todo Morph with .a-thumb-competency?
 */

.a-thumb-download {
	display: flex;
	column-gap: calc(16rem / 16);
	padding-inline-end: calc(8rem / 16);
	text-wrap: balance;
	align-items: center;
	text-decoration: none;
	transition-duration: var(--duration-small);
	transition-property: background-color;

	> div {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		inline-size: calc(64rem / 16);
		padding: calc(8rem / 16);
		aspect-ratio: 1;
		background-color: var(--color-red);
	}
	> div > svg {
		* {
			fill: var(--color-white) !important; /* overwrite style property */
		}
	}


	> strong {
		font: var(--font-label-small);
	}

	&:focus-visible,
	&:hover {
		background-color: var(--color-background-secondary);
	}
}
