.o-competencies {
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	margin-inline: auto;
	padding-inline: var(--padding-inline-body);
	padding-block-start: var(--padding-block-start-main);
}

.o-competencies > nav {
	> ul {
		padding-block-start: var(--gap-block-large);
		display: grid;
		grid-template-columns: repeat(2, minmax(1px, 1fr));
		gap: var(--padding-inline-body);
		list-style: none;

		> li {
			justify-self: stretch;
		}

	}
	@media (min-width: calc(640em / 16)) {
		> ul {
			grid-template-columns: repeat(auto-fill, minmax(calc(180rem / 16), 1fr));
		}
	}
	@media (min-width: calc(800em / 16)) {
		> ul {
			grid-template-columns: repeat(auto-fill, minmax(calc(228rem / 16), 1fr));
		}
	}
	@media (min-width: calc(1600em / 16)) {
		> ul {
			gap: var(--gap-inline);
		}
	}
}

.o-competencies > nav > ul > li {
}

