.o-competency {
	--_columns: 12;
	display: grid;
	grid-template-columns: minmax(1px, 1fr);
	margin-inline: auto;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);
		column-gap: var(--gap-inline);

		> h1 {
			grid-column: 1 / 10;
		}
	}
}


.o-competency__grid {
	margin-block-start: var(--gap-block-large);
	display: inherit;
	grid-template-columns: inherit;
	column-gap: inherit;
	row-gap: var(--gap-block-xlarge);

	&[data-variant="spider"] {
		grid-auto-flow: dense;
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
		grid-template-columns: inherit;
	}
}


.o-competency__text {
	> figure {
		margin-block-end: var(--gap-block-small);
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
	}
	@media (min-width: calc(1152em / 16)) {
		> figure {
			margin-block-end: var(--gap-block-medium);
			inline-size: calc(592rem / 16);
		}
	}
	@media (min-width: calc(1280em / 16)) {
		> figure {
			float: right;
			margin-inline-start: var(--gap-inline);
		}
		grid-column: 1 / 10;
	}
}


.o-competency__person-groups {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);
	@media (min-width: calc(976em / 16)) {
		grid-column: 9 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 10 / -1;
	}
}


.o-competency__topics {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);
	@media (min-width: calc(976em / 16)) {
		grid-column: 9 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 10 / -1;
	}
}


.o-competency__services {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}


.o-competency__references {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);


	@media (min-width: calc(976em / 16)) {
		grid-column: 9 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 10 / -1;
	}

	.m-topics + & {
		margin-block-start: var(--gap-block-xlarge);
	}
}

.o-competency__spider {
	display: flex;
	flex-direction: column;
	row-gap: calc((12rem / 16) * 2);
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);

	> h2 {
		margin-block-end: calc(8rem / 16);
	}

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}


.o-competency__spider-details {
	padding-block-start: calc(28rem / 16);

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}


.o-competency__articles {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
}


.o-competency__events {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}


.o-competency__publications {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);


	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
}


.o-competency__contact {
	margin-block-start: var(--gap-block-large);
	padding-block-start: calc(28rem / 16);
	border-block-start: var(--border-width-medium) solid currentcolor;

	> h2 {
		padding-block-end: calc(32rem / 16);
	}
}


.o-competency__quotes {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}

	.o-competency[data-variant="spider"] & {
		@media (min-width: calc(1280em / 16)) {
			grid-column: 1 / -1;
		}
	}
}
