.o-persons__header {
	inline-size: 100%;
	color: var(--color-content-primary-inverse);
	background-color: var(--color-red);

	> div {
		display: flex;
		flex-direction: column;
		gap: calc(32rem / 16);
		margin-inline: auto;
		max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
		padding-block: var(--padding-block-start-main);
		padding-inline: var(--padding-inline-body);
	}
}


.o-persons__list-container {
	inline-size: 100%;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	margin-inline: auto;
	padding-block-start: var(--padding-block-start-main);
	padding-inline: var(--padding-inline-body);
}


.o-persons__list-container > ul {
	--_columns: 5;

	display: grid;
	grid-template-columns: repeat(2, minmax(1px, 1fr));
	gap: var(--padding-inline-body);
	align-items: stretch;
	list-style: none;

	@media (min-width: calc(640em / 16)) {
		grid-template-columns: repeat(auto-fill, minmax(calc(212rem / 16), 1fr));
		gap: var(--gap-inline);
		grid-auto-rows: 1fr;
	}
}


/* Info, when filter returns no results */
.o-persons__info {
	font: var(--font-paragraph-large);
}
