.o-footer {
	margin-block-start: auto;
	padding-block-start: calc(160rem / 16);
}


.o-footer__container {
	color: var(--color-content-primary-inverse);
	background-color: var(--color-red);
	padding-block-start: calc(80rem / 16);
	padding-block-end: calc(12rem / 16);
	padding-inline: var(--padding-inline-body);
}


.o-footer__center {
	display: grid;
	row-gap: calc(64rem / 16);
	margin-inline: auto;
	max-inline-size: calc(var(--max-width-content));
	inline-size: 100%;

	@media (min-width: calc(1024em / 16)) {
		grid-template-columns: auto auto;
		justify-content: space-between;
		align-items: start;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-template-columns: auto auto auto;
	}
}


.o-footer__logo {
	justify-self: start;
	padding: calc(20rem / 16);
	margin-block-start: calc(20rem / 16 * -1);
	margin-inline-start: calc(20rem / 16 * -1);

	> img {
		block-size: calc(54rem / 16);
		inline-size: calc(167rem / 16);
	}
}


.o-footer__locations {
	display: flex;
	flex-direction: column;
	gap: calc(32rem / 16);
	list-style: none;

	@media (min-width: calc(672em / 16)) {
		grid-column-start: 1;
		flex-direction: row;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column-start: auto;
		column-gap: calc(64rem / 16);
	}
}


.o-footer__nav {
	inline-size: calc(260rem / 16);
	font: var(--font-label-small);

	> ul {
		border-block-end: var(--border-width-small) solid currentcolor;
	}
	> ul > li {
		border-block-start: var(--border-width-small) solid currentcolor;
	}
	> ul > li > a {
		display: flex;
		padding-block: calc(12rem / 16);
		text-decoration: none;
		justify-content: space-between;
		align-items: center;

		&::after {
			content: '';
			display: block;
			block-size: var(--line-height-label-small);
			inline-size: var(--line-height-label-small);
			flex-shrink: 0;
			mask-image: url('/assets/images/symbols/chevron-e.svg');
			mask-size: 100%;
			mask-repeat: no-repeat;
			background-color: currentcolor;
			transition-duration: var(--duration-xsmall);
			transition-property: translate;
		}

		&:focus-visible,
		&:hover {
			&::after {
				translate: calc(3rem / 16) 0;
			}
		}
	}
}


.o-footer__legal-nav {
	grid-column: 1 / -1;
	margin-block-start: calc(32rem / 16);
	padding-block-start: calc(12rem / 16);
	border-block-start: var(--border-width-small) solid currentcolor;
	font: var(--font-label-small);

	a {
		text-decoration: none;

		&:focus-visible, &:hover {
			text-decoration: underline;
		}
	}

	> ul {
		display: flex;
		justify-content: end;
		column-gap: calc(24rem / 16);
	}
}
