.m-text {
	font: var(--font-paragraph-medium);

	* + *:not(br) {
		margin-block-start: calc(var(--line-height-paragraph-medium) * 0.5);
	}

	blockquote + * {
		margin-block-start: calc(32rem / 16);
	}

	a {
		color: var(--color-red);
		text-decoration: none;

		&:focus-visible, &:hover {
			text-decoration: underline;
		}
	}

	hr {
		border-block-start: 0;

		* + &,
		& + * {
			margin-block-start: calc(var(--line-height-paragraph-medium) * 1.5);
		}
		/* border-block-start: var(--border-width-medium) solid currentcolor; */
	}

	> :where(h2, h3, h4, h5, blockquote) {
		* + & {
			margin-block-start: calc(32rem / 16);
		}
	}

	> :where(h2, h3, h4, h5) {
		text-wrap: balance;
	}

	> h2 {
		font: var(--font-heading-large);

		* + & {
			margin-block-start: calc(var(--line-height-paragraph-medium) * 2);
		}
	}
	> h3 {
		font: var(--font-heading-medium);

		* + & {
			margin-block-start: calc(var(--line-height-paragraph-medium) * 2);
		}
	}
	> h4 {
		font: var(--font-heading-small);

		* + & {
			margin-block-start: calc(var(--line-height-paragraph-medium) * 2);
		}
	}

	ol, ul {
		padding-inline-start: calc(22rem / 16);

		li + li {
			margin-block-start: calc(var(--line-height-paragraph-medium) * 0.25);
		}
	}

	ul {
		padding-inline-start: 0;
		list-style: none;

		> li {
			position: relative;
			padding-inline-start: calc(20rem / 16);

			&::before {
				content: '';
				position: absolute;
				inset-block-start: 0.6em;
				inset-block-start: 0.3lh;
				inset-inline-start: 0;
				inline-size: calc(8rem / 16);
				aspect-ratio: 1;
				background-color: currentcolor;
			}

			&:has(strong) {
				&::before {
					inset-block-start: 0.7em;
					inset-block-start: 0.4lh;
				}
			}
		}
	}

	/* makes list number bold, when first list item begins with bold text */
	ol li:has(> p > strong:first-child) {
		font-weight: 700;
		> p {
			font-weight: 400;
		}
	}

	blockquote {
		padding-inline-start: calc(var(--line-height-paragraph-medium) * 0.5);
		font-style: italic;
	}

	figure {
		* + & {
			margin-block-start: calc(var(--line-height-paragraph-medium));
		}
	}

	& + & {
		margin-block-start: calc(var(--line-height-paragraph-medium) * 2);
	}

	&[data-size="small"] {
		font: var(--font-paragraph-small);
	}

	&[data-size="large"] {
		font: var(--font-paragraph-large);
	}

	&[data-variant="medium"] {
		font-weight: 500;
	}

	&[data-blockquote="large"] {
		blockquote {
			margin-block-start: calc(var(--line-height-paragraph-medium) * 2);
			position: relative;
			padding-inline-start: calc(56rem / 16);
			font: var(--font-paragraph-xlarge);
			font-weight: 500;
			color: var(--color-red);

			&::before {
				content: '';
				position: absolute;
				inset-inline-start: 0;
				inset-block-start: 0;
				display: block;
				inline-size: calc(40rem / 16);
				aspect-ratio: 172 / 143;
				mask-image: url('/assets/images/symbols/quotation-marks.svg');
				mask-size: 100%;
				mask-repeat: no-repeat;
				background-color: var(--color-red);
			}
		}
	}
}
