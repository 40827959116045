/* Could be div or details element */
.m-competency-spider-detail {
	.m-competency-spider + & {
	  margin-block-start: calc(72rem / 16);
	}
  & + & {
	  margin-block-start: calc(72rem / 16);
  }
}
details.m-competency-spider-detail {
	& + & {
		margin-block-start: calc(0rem / 16);
	}
	&[open] + & {
		margin-block-start: calc(72rem / 16);
	}
}

.m-competency-spider-detail__header {
	margin-block-end: calc(24rem / 16);
	padding-block-end: calc(8rem / 16);
	border-block-end: var(--border-width-small) solid currentcolor;
}
summary.m-competency-spider-detail__header {
	display: flex;
	justify-content: space-between;
	column-gap: calc(12rem / 16);
	list-style: none; /* Removes the default triangle */

	&::after {
		content: '';
		align-self: start;
		flex-shrink: 0;
		inline-size: calc(20rem / 16);
		block-size: calc(20rem / 16);
		mask-image: url('/assets/images/symbols/chevron-dropdown.svg');
		mask-size: 160%;
		mask-repeat: no-repeat;
		mask-position: center;
		background-color: var(--color-red);
		transition-duration: var(--duration-large), var(--duration-small);
		transition-property: rotate, translate;

		details[open] & {
			rotate: -180deg;
		}
	}

	&:focus-visible, &:hover {
		&::after {
			translate: 0 calc(2rem / 16);

			details[open] & {
				translate: 0 calc(-1rem / 16);
			}
		}
	}
}

.m-competency-spider-detail__persons {
	--_image-inline-size: calc(72rem / 16);

	margin-block-start: calc(40rem / 16);
	font: var(--font-paragraph-small);
	list-style: none;
	border-block-start: var(--border-width-small) solid currentcolor;

	> li {
		border-block-end: var(--border-width-small) solid currentcolor;
	}

	> li > a {
		padding-block: calc(12rem / 16);
		display: grid;
		grid-template-columns: var(--_image-inline-size) 1fr auto;
		column-gap: var(--padding-inline-body);
		align-items: center;
		align-content: center;
		text-decoration: none;

		&::after {
			content: '';
			display: block;
			block-size: var(--line-height-label-small);
			inline-size: var(--line-height-label-small);
			flex-shrink: 0;
			mask-image: url('/assets/images/symbols/chevron-e.svg');
			mask-size: 100%;
			mask-repeat: no-repeat;
			background-color: currentcolor;
			transition-duration: var(--duration-xsmall);
			transition-property: translate;
		}

		&:focus-visible,
		&:hover {
			&::after {
				translate: calc(3rem / 16) 0;
			}
		}
	}

	> li > a > img {
		inline-size: 100%;
	}
	> li > a > strong {
		font-weight: 500;
	}
	> li > a > em {
		font-weight: 400;
		font-style: normal;
		color: var(--color-content-secondary);
	}

	@media not (min-width: calc(976em / 16)) {
		> li > a {
			&::after {
				grid-row-end: span 2;
			}
		}
		> li > a > img {
			grid-row-end: span 2;
		}
		> li > a > strong {
			grid-column-start: 2;
			grid-row-start: 1;
			align-self: end;
		}
		> li > a > em {
			grid-column-start: 2;
			grid-row-start: 2;
			align-self: start;
		}
	}
	@media (min-width: calc(976em / 16)) {
		> li > a {
			grid-template-columns: var(--_image-inline-size) 24ch 1fr auto;
			column-gap: var(--gap-inline);
		}
	}
}
