.m-field {
	display: grid;
	grid-template-columns: minmax(1px, 1fr);
}


.m-field__label {
	grid-column: 1 / -1;
	grid-row: 1 / -1;
	z-index: 1;
	align-self: center;
	padding-block-start: calc(4rem / 16);
	padding-inline-start: calc(20rem / 16);
	color: var(--color-content-secondary);
	text-transform: uppercase;
	font: var(--font-label-medium);
	letter-spacing: var(--letter-spacing-uppercase);
	pointer-events: none;
	opacity: 0;

	.m-field[data-theme="red"] & {
		color: color-mix(in hsl, var(--color-white) 70%, var(--color-red));
	}

	.m-field:has(input:placeholder-shown) &,
	.m-field:has(select option:not([value]):checked) & {
		opacity: 1;
	}
}


.m-field > .a-input {
	grid-column: 1 / -1;
	grid-row: 1 / -1;
}
