/**
 * Used for competencies overview on home page and competencies template.
 * Also used in competency-spider
 */

.a-card-competency {
	display: block;
	block-size: 100%;
	padding-block-start: calc(20rem / 16);
	padding-block-end: calc(24rem / 16);
	padding-inline: calc(12rem / 16);
	font: var(--font-label-small);
	text-decoration: none;
	text-align: center;
	text-wrap: balance;
	border: var(--border-width-small) solid currentcolor;
	transition-duration: var(--duration-xsmall);
	transition-property: border-color, color, background-color;

	> svg {
		max-inline-size: 60%;
		margin-inline: auto;
		margin-block-end: calc(20rem / 16);

		* {
			transition-duration: inherit;
			transition-property: fill;
		}
	}

	&:not([data-theme="red"]) {
		&:focus-visible,
		&:hover {
			border-color: var(--color-red);
			color: var(--color-white);
			background-color: var(--color-red);

			> svg * {
				fill: currentcolor !important; /* overwrite style property */
			}
		}
	}

	&[data-theme="red"] {
		color: var(--color-content-primary-inverse);
		background-color: var(--color-red);

		&:not(:focus-visible, :hover) {
			> svg * {
				fill: currentcolor !important; /* overwrite style property */
			}
		}

		&:focus-visible,
		&:hover {
			border-color: var(--color-black);
			color: unset;
			background-color: unset;
		}
	}

	@media (min-width: calc(800em / 16)) {
		padding-block-start: calc(20rem / 16);
	}
	@media (min-width: calc(1280em / 16)) {
		aspect-ratio: 1;
	}
}
