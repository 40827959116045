.m-events {
	> h2 + ul {
		margin-block-start: calc(32rem / 16);
	}

	> ul + .a-button {
		margin-block-start: calc(32rem / 16);
	}
}

.m-events > ul {
	display: grid;
	column-gap: var(--gap-inline);
	row-gap: calc(28rem / 16);
	list-style: none;


	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(12, 1fr);

		> li {
			grid-column-end: span 6;
		}

		&:has([data-highlight]) {
			max-inline-size: calc(1104rem / 16);
			grid-template-columns: repeat(9, 1fr);

			> li {
				grid-column-end: span 4;
			}
			> li[data-highlight] {
				grid-column-end: span 5;
				grid-row-end: span 5;
			}
		}
	}
	@media (min-width: calc(1152em / 16)) {
		> li {
			grid-column-end: span 4;
		}
	}
}
