.m-event-details {
	display: flex;
	block-size: 100%;
	flex-direction: column;
	padding-inline: calc(32rem / 16);
	padding-block-start: calc(36rem / 16);
	padding-block-end: calc(36rem / 16);
	font: var(--font-label-medium);

	> p {
		text-wrap: balance;
	}
	> p[data-size="small"] {
		font: var(--font-label-small);
		font-size: calc(16rem / 16);
	}

	> * + h2 {
		margin-block-start: calc(var(--line-height-label-medium) * 1.5);
	}
	> h2 + * {
		margin-block-start: calc(var(--line-height-label-medium) * 0.5);
	}

	> p + p {
		margin-block-start: calc(var(--line-height-label-medium) * 1);
	}

	&[data-variant="cancelled"] {
		> *:not(h2:not([data-size="medium"])) {
			color: var(--color-content-secondary-inverse);
		}
	}
}
