.m-job-postings {
	> h2 + ul {
		margin-block-start: calc(32rem / 16);
	}
}

.m-job-postings > ul {
	border-block-start: var(--border-width-small) solid currentcolor;
	list-style: none;

	> li {
		border-block-end: var(--border-width-small) solid currentcolor;
	}
	> li > a {
		grid-column: 1 / -1;
		display: grid;
		grid-template-columns: 1fr auto;
		padding-block: calc(12rem / 16);
		display: grid;
		column-gap: var(--padding-inline-body);
		align-items: center;
		align-content: center;
		text-decoration: none;

		strong {
			font-weight: 500;
			text-wrap: balance;
		}

		em {
			font-style: normal;
			color: var(--color-content-secondary);
		}

		&::after {
			content: '';
			display: block;
			block-size: var(--line-height-label-small);
			inline-size: var(--line-height-label-small);
			flex-shrink: 0;
			mask-image: url('/assets/images/symbols/chevron-e.svg');
			mask-size: 100%;
			mask-repeat: no-repeat;
			background-color: currentcolor;
			transition-duration: var(--duration-xsmall);
			transition-property: translate;
		}

		&:focus-visible,
		&:hover {
			&::after {
				translate: calc(3rem / 16) 0;
			}
		}
	}

	@media not (min-width: calc(976em / 16)) {
		> li > a {
			&::after {
				grid-row-end: span 2;
			}
		}
		> li > a > strong {
			grid-column-start: 1;
			grid-row-start: 1;
			align-self: end;
		}
		> li > a > em {
			grid-column-start: 1;
			grid-row-start: 2;
			align-self: start;
		}
	}
	@media (min-width: calc(976em / 16)) {
		> li > a {
			grid-template-columns: 1fr 1.5fr auto;
			column-gap: var(--gap-inline);
		}
	}
}
