/* FOR ADMINS */

.a-edit-link {
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
	padding: calc(4rem / 16) calc(8rem / 16);
	color: var(--color-white);
	background-color: var(--color-black);
	opacity: 0;
	font: var(--font-label-small);
	text-decoration: none;
	z-index: calc(var(--z-index-nav-sticky) + 1);

	&:hover, &:focus-visible {
		opacity: 1;
	}
}
