/**
 * Each size should divide by 4.
 */

:root {
	/* Font family */
	--font-family: 'FF Meta', sans-serif;

	/* Font size */
	--font-size-display-xlarge: calc(70rem / 16);
	--font-size-display-large: calc(60rem / 16);
	--font-size-display-medium: calc(50rem / 16);

	--font-size-heading-large: calc(30rem / 16);
	--font-size-heading-medium: calc(21rem / 16);
	--font-size-heading-small: calc(18rem / 16);

	--font-size-label-large: calc(22rem / 16);
	--font-size-label-medium: calc(18rem / 16);
	--font-size-label-small: calc(15rem / 16);
	--font-size-label-xsmall: calc(13rem / 16);
	--font-size-label-xxsmall: calc(12rem / 16);

	--font-size-paragraph-xlarge: calc(24rem / 16);
	--font-size-paragraph-large: calc(20rem / 16);
	--font-size-paragraph-medium: calc(18rem / 16);
	--font-size-paragraph-small: calc(16rem / 16);
	--font-size-paragraph-xsmall: calc(14rem / 16);
	--font-size-paragraph-xxsmall: calc(12rem / 16);

	--font-size: var(--font-size-paragraph-medium);

	/* Line height */
	--line-height-display-xlarge: calc(112rem / 16);
	--line-height-display-large: calc(112rem / 16);
	--line-height-display-medium: calc(56rem / 16);

	--line-height-heading-large: calc(34rem / 16);
	--line-height-heading-medium: calc(24rem / 16);
	--line-height-heading-small: calc(20rem / 16);

	--line-height-label-large: calc(28rem / 16);
	--line-height-label-medium: calc(22rem / 16);
	--line-height-label-small: calc(20rem / 16);
	--line-height-label-xsmall: calc(16rem / 16);
	--line-height-label-xxsmall: calc(16rem / 16);

	--line-height-paragraph-xlarge: calc(36rem / 16);
	--line-height-paragraph-large: calc(30rem / 16);
	--line-height-paragraph-medium: calc(30rem / 16);
	--line-height-paragraph-small: calc(24rem / 16);
	--line-height-paragraph-xsmall: calc(18rem / 16);
	--line-height-paragraph-xxsmall: calc(16rem / 16);

	--line-height: var(--line-height-paragraph-medium);

	/* Font */
	--font-display-xlarge: 500 var(--font-size-display-xlarge)/var(--line-height-display-xlarge) var(--font-family);
	--font-display-large: 500 var(--font-size-display-large)/var(--line-height-display-large) var(--font-family);
	--font-display-medium: 500 var(--font-size-display-medium)/var(--line-height-display-medium) var(--font-family);

	--font-heading-large: 500 var(--font-size-heading-large)/var(--line-height-heading-large) var(--font-family);
	--font-heading-medium: 500 var(--font-size-heading-medium)/var(--line-height-heading-medium) var(--font-family);
	--font-heading-small: 500 var(--font-size-heading-small)/var(--line-height-heading-small) var(--font-family);

	--font-label-large: 400 var(--font-size-label-large)/var(--line-height-label-large) var(--font-family);
	--font-label-medium: 400 var(--font-size-label-medium)/var(--line-height-label-medium) var(--font-family);
	--font-label-small: 400 var(--font-size-label-small)/var(--line-height-label-small) var(--font-family);
	--font-label-xsmall: 400 var(--font-size-label-xsmall)/var(--line-height-label-xsmall) var(--font-family);
	--font-label-xxsmall: 400 var(--font-size-label-xxsmall)/var(--line-height-label-xxsmall) var(--font-family);

	--font-paragraph-xlarge: 400 var(--font-size-paragraph-xlarge)/var(--line-height-paragraph-xlarge) var(--font-family);
	--font-paragraph-large: 400 var(--font-size-paragraph-large)/var(--line-height-paragraph-large) var(--font-family);
	--font-paragraph-medium: 400 var(--font-size-paragraph-medium)/var(--line-height-paragraph-medium) var(--font-family);
	--font-paragraph-small: 400 var(--font-size-paragraph-small)/var(--line-height-paragraph-small) var(--font-family);
	--font-paragraph-xsmall: 400 var(--font-size-paragraph-xsmall)/var(--line-height-paragraph-xsmall) var(--font-family);
	--font-paragraph-xxsmall: 400 var(--font-size-paragraph-xxsmall)/var(--line-height-paragraph-xxsmall) var(--font-family);

	--font: var(--font-paragraph-medium);

	/* Letter spacing */
	--letter-spacing-display: -0.01em;
	--letter-spacing-uppercase: 0.04em;

	/* Other */
	--hyphenate-limit: 6;

	@media not (min-width: calc(560em / 16)) {
		--font-size-display-medium: calc(40rem / 16);

		--line-height-display-medium: calc(44rem / 16);
	}
}
