.a-nav-link {
	--_padding-inline: calc(20rem / 16);

	display: block;
	padding-block: calc(20rem / 16);
	padding-inline: var(--_padding-inline);
	border: 0; /* resets button */
	font: var(--font-label-medium);
	text-decoration: none;
	white-space: nowrap;

	svg {
		block-size: 1.2em;
		block-size: 1lh;
		inline-size: auto;
		margin-block-start: calc(-2rem / 16);

		* {
			fill: currentcolor !important; /* overwrite style property */
		}
	}

	&[data-selected] {
		font-weight: 500;
	}

	&[data-type="primary"] {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			inset-block-end: 0;
			inset-inline-start: calc(50% - var(--border-width-medium) * 0.5);
			block-size: calc(16rem / 16);
			border-inline-start: var(--border-width-medium) solid currentcolor;
			scale: 1 0;
			transform-origin: center bottom;
			transition-duration: var(--duration-medium);
			transition-property: scale;
		}

		&[data-selected] {
			&::after {
				scale: 1 1;
			}
		}
	}

	&:focus-visible,
	&:hover {
		&[data-type="primary"]:not([data-selected]) {
			&::after {
				scale: 1 .5;
				transition-timing-function: ease-out;
			}
		}
	}

	@media not (min-width: calc(1152em / 16)), (max-width: 72em) {
		&[data-type="primary"] {
			font: var(--font-label-large);
			text-align: end;

			&::after {
				inset-inline-start: auto;
				inset-inline-end: 0;
				inset-block-start: calc(50% - var(--border-width-medium) * 0.5);
				inset-block-end: autp;
				block-size: 0;
				inline-size: calc(8rem / 16);
				border-inline-start: 0;
				border-block-end: var(--border-width-medium) solid currentcolor;
				scale: 0 1;
				transform-origin: right center;
			}
		}
	}
	@media (min-width: calc(1152em / 16)), (min-width: 72em) {
		&[data-type="hamburger"] {
			display: none;
		}
	}
	@media not (min-width: calc(448em / 16)) {
		--_padding-inline: calc(16rem / 16);
	}
}
