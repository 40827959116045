.a-pagination {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: baseline;
	gap: calc(12rem / 16);
	font: var(--font-label-small);
	color: var(--color-content-secondary);

	&[data-align="start"] {
		justify-content: start;
	}
}

.a-pagination__seperator {
	--_padding-inline: var(--gap-x-small);
	min-inline-size: calc(1lh + var(--_padding-inline) * 2);
	text-align: center;
}
