.m-card-article {
	--_rows: 5;
	display: grid;
	grid-template-columns: minmax(1px, 1fr);
	grid-template-rows: repeat(var(--_rows), auto);
	row-gap: calc(16rem / 16);
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);
	text-decoration: none;

	> time {
		display: block;
		text-transform: uppercase;
		font: var(--font-label-xsmall);
		letter-spacing: var(--letter-spacing-uppercase);
	}

	> strong {
		display: block;
		font: var(--font-heading-medium);
		font-weight: 400;

		> em {
			display: block;
			font-style: normal;
			font-weight: 500;
		}
	}

	> time + strong {
		/* margin-block-start: calc(12rem / 16); */
	}

	> figure {
		aspect-ratio: 460 / 200;
		background-color: var(--color-background-tertiary);

		> img {
			inline-size: 100%;
		}
	}

	&[data-variant="news"],
	&[data-variant="subgrid"] {
		grid-template-rows: subgrid;
		grid-row-end: span var(--_rows);
		row-gap: unset;
	}

	&[data-variant="news"] {
		> figure {
			> img {
				inline-size: 100%;
				border: var(--border-width-small) solid var(--color-red);
			}
		}

	}

	.a-icon {
		transition-duration: var(--duration-xsmall);
		transition-property: color, background-color;
	}

	&:focus-visible,
	&:hover {
		.a-icon {
			color: var(--color-content-primary);
			background-color: var(--color-background-tertiary);
		}
	}
}


.m-card-article__teaser {
	font: var(--font-paragraph-small);
}

.m-card-article__meta {
	margin-block-start: calc(16rem / 16);
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: calc(12rem / 16);
	text-wrap: balance;
	font: var(--font-label-small);

	> .a-icon {
		flex-shrink: 0;
	}
}
