/**
 * Each size should divide by 4.
 * Each size must be in rem. Write like this: calc([equivalent pixel size]rem / 16)
 * A equivalent to 20px is calc(20rem / 16)
 * Prefer css variables defined here (e.g. var(--gap-inline)) over custom size (e.g. calc(48rem / 16)) in components
 *
 * @see https://www.joshwcomeau.com/css/surprising-truth-about-pixels-and-accessibility/#calculated-values-14
 */

/**
 * Media Queries
 * Variables can’t be used with media queries.
 * Use em values. Always use min-width (no max-width).
 * E.g. @media (min-width: calc(*em / 16)).
 * You can use @media not (min-width: calc(*em / 16))
 *
 * Common variables:
 * Hamburger nav: calc(1152em / 16)
 * Change in grid span: calc(976em / 16) (e.g. in .o-competency__topics)
 */

:root {
	/* Grid */
	--gap-inline: calc(48rem / 16);
	--gap-block-small: calc(40rem / 16);
	--gap-block-medium: calc(60rem / 16);
	--gap-block-large: calc(80rem / 16); /* used for margin between h1 and content */
	--gap-block-xlarge: calc(144rem / 16); /* used for margin between blocks */
	--padding-inline-body: calc(32rem / 16);
	--padding-block-start-main: calc(48rem / 16); /* padding/margin above h1 */

	/* Sizes */
	--max-width: calc(1600rem / 16);
	--max-width-content: calc(1488rem / 16);
	--max-width-text: calc(1104rem / 16);

	/* Borders */
	--border-width-medium: 2px; /* always use pixel to avoid different subpixel aliasing  */
	--border-width-small: 1px; /* always use pixel to avoid different subpixel aliasing  */

	/* Z-Index */
	--z-index-nav-sticky: 300;
	--z-index-dialog: 500;
	--z-index-toasts: 600;

	@media not (min-width: calc(560em / 16)) {
		--gap-inline: calc(32rem / 16);
		--gap-block-medium: calc(40rem / 16);
		--gap-block-large: calc(48rem / 16); /* used for margin between h1 and content */
		--gap-block-xlarge: calc(96rem / 16); /* used for margin between blocks */
		--padding-inline-body: calc(20rem / 16);
	}
	@media not (min-width: calc(448em / 16)) {
		--padding-inline-body: calc(16rem / 16);
	}
}
