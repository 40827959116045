.a-button {
	--_border-width: var(--border-width-small);

	display: inline-flex;
	justify-content: center;
	align-items: center;
	column-gap: calc(8rem / 16);
	font: var(--font-label-medium);
	padding-block: calc(12rem / 16) calc(8rem / 16);
	padding-inline: calc(20rem / 16);
	color: currentcolor;
	border: var(--_border-width) solid currentcolor;
	text-decoration: none;
	text-transform: uppercase;
	font: var(--font-label-medium);
	font-weight: 500;
	letter-spacing: var(--letter-spacing-uppercase);
	transition-duration: var(--duration-small);
	transition-property: background-color, border-color, color;

	> svg {
		margin-block-start: -0.2lh;
		block-size: 1lh;
		inline-size: auto;

		* {
			fill: currentcolor !important; /* overwrite style property */
		}
	}

	&[data-size="small"] {
		font: var(--font-label-small);
		font-weight: 500;
		padding-block: calc(6rem / 16) calc(2rem / 16);
		padding-inline: calc(20rem / 16);
	}

	&[data-size="xsmall"] {
		font: var(--font-label-xsmall);
		font-weight: 500;
		padding-block: calc(7rem / 16) calc(4rem / 16);
		padding-inline: calc(20rem / 16);
	}

	&[data-kind="secondary"] {
		color: var(--color-content-secondary);
	}

	&[data-shape="squared"] {
		min-inline-size: calc(1lh + (3rem / 16) + (3rem / 16) + var(--_border-width) * 2);
		min-block-size: calc(1lh + (3rem / 16) + (3rem / 16) + var(--_border-width) * 2);
		text-align: center;
		padding-block: calc(3rem / 16) calc(3rem / 16);
		padding-inline: calc(3rem / 16);
		font-variant: tabular-nums; /* font does not support tabular-nums */

		> svg {
			margin-block: auto;
		}
	}

	&[data-shape="text"] {
		border: 0;
		padding-inline: calc(12rem / 16);
	}

	&[data-inverse] {
		color: var(--color-white);
	}

	&[data-selected] {
		color: var(--color-content-primary-inverse);
		border-color: var(--color-red);
		background-color: var(--color-red);
	}

	&:focus-visible,
	&:hover {
		color: var(--color-white);
		border-color: var(--color-black);
		background-color: var(--color-black);

		&[data-shape="text"] {
			color: var(--color-content-primary);
			border-color: transparent;
			background-color: transparent;
		}

		&[data-inverse] {
			color: var(--color-red);
			border-color: var(--color-white);
			background-color: var(--color-white);
			outline: 0;
		}
	}
}
