.o-job-posting > header {
	padding-block-start: var(--gap-block-medium);
	padding-block-end: var(--gap-block-large);
	padding-inline: var(--padding-inline-body);
	color: var(--color-white);
	background: var(--color-red);

	> div {
		max-inline-size: var(--max-width-content);
		margin-inline: auto;
	}
}

.o-job-posting__grid {
	--_columns: 12;

	margin-block-start: var(--gap-block-large);
	margin-inline: auto;
	max-inline-size: calc(var(--max-width-content) + var(--padding-inline-body) * 2);
	inline-size: 100%;
	padding-inline: var(--padding-inline-body);
	display: grid;
	grid-template-columns: minmax(1px, 1fr);
	column-gap: var(--gap-inline);
	row-gap: var(--gap-block-xlarge);

	@media (min-width: calc(976em / 16)) {
		grid-template-columns: repeat(var(--_columns), 1fr);
	}
}

.o-job-posting__text {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / 9;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 1 / 10;
	}
}

.o-job-posting__downloads {
	border-block-start: var(--border-width-medium) solid currentcolor;
	padding-block-start: calc(28rem / 16);

	@media (min-width: calc(976em / 16)) {
		grid-column: 9 / -1;
	}
	@media (min-width: calc(1280em / 16)) {
		grid-column: 10 / -1;
	}
}


.o-job-posting__person-groups {
	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(
			min(calc(512rem / 16), calc(100vw - var(--padding-inline-body) * 2)),
			1fr)
		);
	gap: var(--gap-inline);
	list-style: none;

	@media (min-width: calc(976em / 16)) {
		grid-column: 1 / -1;
	}
}
